import { useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import type { AxiosError } from 'axios';
import { VerificationFlowState } from '@ory/client';
import type {
  VerificationFlow,
  UpdateVerificationFlowBody,
} from '@ory/client';
import clsx from 'clsx';

import ory from '../../api';
import { Flow } from '../../components/Flow';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/CardTitle';
import { Messages } from '../../components/Messages';

import sCard from '../../styles/card.module.css';

const Verification = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const flowId = searchParams.get('flow');
  const returnTo = searchParams.get('return_to');

  const [flow, setFlow] = useState<VerificationFlow>();

  useEffect(() => {
    if (flow) {
      return;
    }

    if (flowId) {
      ory
        .getVerificationFlow({ id: String(flowId) })
        .then(({ data }) => setFlow(data))
        .catch((error: AxiosError) => {
          const responseStatus = error.response?.status;
          const responseData = error.response?.data;

          switch (responseStatus) {
            // Flow expired - request a new one
            case 410:
              navigate('/verification');
              return;

            // Form validation error
            case 400:
              setFlow(responseData);
              return;

            default:
              throw error;
          }
        });

      return;
    }

    ory
      .createBrowserVerificationFlow({ returnTo: returnTo ? String(returnTo) : undefined })
      .then(({ data }) => {
        setFlow(data);
      })
      .catch((error: AxiosError) => {
        const responseStatus = error.response?.status;

        switch (responseStatus) {
          // User is already signed in
          case 400:
            navigate('/');
            return;

          default:
            throw error;
        }
      });
  }, [navigate, flow, flowId, returnTo]);

  const onSubmit = (values: UpdateVerificationFlowBody) => {
    if (flow) {
      setSearchParams(new URLSearchParams([['flow', flow.id]]));
    }

    return ory
      .updateVerificationFlow({
        flow: String(flow?.id), 
        updateVerificationFlowBody: values
      })
      .then(({ data }) => setFlow(data))
      .catch((error: AxiosError) => {
        const responseStatus = error.response?.status;
        const responseData = error.response?.data;

        switch (responseStatus) {
          // Form validation error
          case 400:
            setFlow(responseData);
            return;

          default:
            throw error;
        }
      });
  };

  return (
    <>
      <Helmet defer={false} title={t('verification.documentTitle')} />

      <Card className={clsx(sCard.marginCard)}>
        <CardTitle>{t('verification.form.title')}</CardTitle>

        {flow?.state === VerificationFlowState.PassedChallenge ? (
          <Messages flowType="verification" messages={flow?.ui.messages} />
        ) : (
          <Flow flow={flow} flowType="verification" onSubmit={onSubmit} />
        )}
      </Card>

      <Card className={clsx(sCard.marginCard, sCard.centerCard)}>
        <Link to="/">{t('verification.navigation.home')}</Link>
      </Card>
    </>
  );
};

export { Verification };
