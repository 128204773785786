import { useEffect } from 'react';
import type { HTMLAttributeReferrerPolicy } from 'react';
import type { UiNode, UiNodeScriptAttributes } from '@ory/client';

import type { FlowType } from '../../../types/helpers';

interface Props {
  flowType: FlowType;
  node: UiNode;
  attributes: UiNodeScriptAttributes;
}

export const NodeScript = ({ flowType, attributes }: Props) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.async = true;
    script.src = attributes.src;
    script.async = attributes.async;
    script.crossOrigin = attributes.crossorigin;
    script.integrity = attributes.integrity;
    script.referrerPolicy = attributes.referrerpolicy as HTMLAttributeReferrerPolicy;
    script.type = attributes.type;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [attributes]);

  return null;
};
