import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import type { AxiosError } from 'axios';
import type { SettingsFlow, UpdateSettingsFlowBody } from '@ory/client';
import clsx from 'clsx';

import ory from '../../api';
import { Flow } from '../../components/Flow';
import { CardTitle } from '../../components/CardTitle';
import { Card } from '../../components/Card';
import { SettingsCard } from '../../components/SettingsCard';
import { Messages } from '../../components/Messages';
import { handleFlowError } from '../../utils/errors';

import sCard from '../../styles/card.module.css';
import { Button } from '../../components/Button';

export const Settings = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const flowId = searchParams.get('flow');
  const returnTo = searchParams.get('return_to');

  const [flow, setFlow] = useState<SettingsFlow>();

  useEffect(() => {
    if (flow) {
      return;
    }

    if (flowId) {
      ory
        .getSettingsFlow({ id: String(flowId) })
        .then(({ data }) => setFlow(data))
        .catch(handleFlowError(t, navigate, 'settings', setFlow))
        .catch((error: AxiosError) => {
          const responseStatus = error.response?.status;

          switch (responseStatus) {
            case 401:
              navigate('/signin');
              return;

            default:
              throw error;
          }
        });

      return;
    }

    ory
      .createBrowserSettingsFlow({ returnTo: returnTo ? String(returnTo) : undefined })
      .then(({ data }) => setFlow(data))
      .catch(handleFlowError(t, navigate, 'settings', setFlow))
      .catch((error: AxiosError) => {
        const responseStatus = error.response?.status;

        switch (responseStatus) {
          case 401:
            navigate('/signin');
            return;

          default:
            throw error;
        }
      });
  }, [t, navigate, flow, flowId, returnTo]);

  const onSubmit = (values: UpdateSettingsFlowBody) => {
    if (flow) {
      setSearchParams(new URLSearchParams([['flow', flow.id]]));
    }

    return ory
      .updateSettingsFlow({
        flow: String(flow?.id), 
        updateSettingsFlowBody: values
      })
      .then(({ data }) => setFlow(data))
      .catch(handleFlowError(t, navigate, 'settings', setFlow))
      .catch((error: AxiosError) => {
        const responseStatus = error.response?.status;
        const responseData = error.response?.data;

        switch (responseStatus) {
          // Form validation error
          case 400:
            setFlow(responseData);
            return;

          default:
            throw error;
        }
      });
  };

  return (
    <>
      <Helmet defer={false} title={t('settings.documentTitle')} />

      <SettingsCard className={clsx(sCard.marginCard)} flow={flow} onlyMethodToShow="profile">
        <CardTitle>{t('settings.cards.profile.title')}</CardTitle>

        <Messages flowType="settings" messages={flow?.ui.messages} />

        <Flow
          flow={flow}
          flowType="settings"
          onlyMethodToShow="profile"
          onSubmit={onSubmit}
          hideGlobalMessages
        />
      </SettingsCard>

      <SettingsCard className={clsx(sCard.marginCard)} flow={flow} onlyMethodToShow="password">
        <CardTitle>{t('settings.cards.password.title')}</CardTitle>

        <Flow
          flow={flow}
          flowType="settings"
          onlyMethodToShow="password"
          onSubmit={onSubmit}
          hideGlobalMessages
        />
      </SettingsCard>

      <SettingsCard className={clsx(sCard.marginCard)} flow={flow} onlyMethodToShow="oidc">
        <CardTitle>{t('settings.cards.oidc.title')}</CardTitle>

        <Flow
          flow={flow}
          flowType="settings"
          onlyMethodToShow="oidc"
          onSubmit={onSubmit}
          hideGlobalMessages
        />
      </SettingsCard>

      <SettingsCard className={clsx(sCard.marginCard)} flow={flow} onlyMethodToShow="lookup_secret">
        <CardTitle>{t('settings.cards.lookup_secret.title')}</CardTitle>

        <p>
          Recovery codes can be used in panic situations where you have lost access to your 2FA
          device.
        </p>

        <Flow
          flow={flow}
          flowType="settings"
          onlyMethodToShow="lookup_secret"
          onSubmit={onSubmit}
          hideGlobalMessages
        />
      </SettingsCard>

      <SettingsCard className={clsx(sCard.marginCard)} flow={flow} onlyMethodToShow="totp">
        <CardTitle>{t('settings.cards.totp.title')}</CardTitle>

        <p>
          Add a TOTP Authenticator App to your account to improve your account security. Popular
          Authenticator Apps are{' '}
          <a href="https://www.lastpass.com" rel="noreferrer" target="_blank">
            LastPass
          </a>{' '}
          and Google Authenticator (
          <a
            href="https://apps.apple.com/us/app/google-authenticator/id388497605"
            target="_blank"
            rel="noreferrer"
          >
            iOS
          </a>
          ,{' '}
          <a
            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US"
            target="_blank"
            rel="noreferrer"
          >
            Android
          </a>
          ).
        </p>

        <Flow
          flow={flow}
          flowType="settings"
          onlyMethodToShow="totp"
          onSubmit={onSubmit}
          hideGlobalMessages
        />
      </SettingsCard>

      <SettingsCard className={clsx(sCard.marginCard)} flow={flow} onlyMethodToShow="webauthn">
        <CardTitle>{t('settings.cards.webauthn.title')}</CardTitle>

        <p>
          Use Hardware Tokens (e.g. YubiKey) or Biometrics (e.g. FaceID, TouchID) to enhance your
          account security.
        </p>

        <Flow
          flow={flow}
          flowType="settings"
          onlyMethodToShow="webauthn"
          onSubmit={onSubmit}
          hideGlobalMessages
        />
      </SettingsCard>

      <Card className={clsx(sCard.marginCard)}>
        <Button onClick={_ => window.alert(t('settings.cards.delete.message'))}>
          {t('settings.cards.delete.title')}
        </Button>
      </Card>
    </>
  );
};
