import clsx from 'clsx';

import s from './styles.module.css';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  severity?: 'error' | 'info';
}

export const Alert = ({ severity = 'info', className, ...props }: Props) => (
  <div
    className={clsx(
      s.alert,
      severity === 'info' && s.alertInfo,
      severity === 'error' && s.alertError,
      className,
    )}
    {...props}
  />
);
