import { useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import type { AxiosError } from 'axios';
import type { RecoveryFlow, UiContainer, UpdateRecoveryFlowBody } from '@ory/client';
import clsx from 'clsx';

import ory from '../../api';
import { Flow } from '../../components/Flow';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/CardTitle';
import { handleFlowError } from '../../utils/errors';

import sCard from '../../styles/card.module.css';

export const Recovery = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const flowId = searchParams.get('flow');
  const returnTo = searchParams.get('return_to');

  const [flow, setFlow] = useState<RecoveryFlow>();

  useEffect(() => {
    if (flow) {
      return;
    }

    if (flowId) {
      ory
        .getRecoveryFlow({ id: String(flowId) })
        .then(({ data }) => setFlow(data))
        .catch(handleFlowError(t, navigate, 'recovery', setFlow));

      return;
    }

    ory
      .createBrowserRecoveryFlow()
      .then(({ data }) => setFlow(data))
      .catch(handleFlowError(t, navigate, 'recovery', setFlow))
      .catch((error: AxiosError) => {
        const responseStatus = error.response?.status;

        switch (responseStatus) {
          // Form validation error
          case 400:
            navigate('/');
            return;

          default:
            throw error;
        }
      });
  }, [t, navigate, flow, flowId, returnTo]);

  const onSubmit = (values: UpdateRecoveryFlowBody) => {
    if (flow) {
      setSearchParams(new URLSearchParams([['flow', flow.id]]));
    }

    return ory
      .updateRecoveryFlow({
        flow: String(flow?.id), 
        updateRecoveryFlowBody: values
      })
      .then(({ data }) => setFlow(data))
      .catch(handleFlowError(t, navigate, 'recovery', setFlow))
      .catch((error: AxiosError) => {
        const responseStatus = error.response?.status;
        const responseData = error.response?.data;

        switch (responseStatus) {
          // Form validation error
          case 400:
            setFlow(responseData);
            return;

          default:
            throw error;
        }
      });
  };

  function removeResendCodeButton(flow : RecoveryFlow)
  {
    if(flow && flow.ui) 
    {
      const index = flow.ui.nodes.findIndex(node => {
        const a : any = node.attributes;
        return a.type == "submit" && a.name == "email";
      }); // Resend code button
      if(index >= 0)
        flow.ui.nodes.splice(index, 1)
    }
    return flow;
  }

  return (
    <>
      <Helmet defer={false} title={t('recovery.documentTitle')} />

      {flow && flow.ui && (
        <Card className={clsx(sCard.marginCard)}>
          <CardTitle>{t('recovery.form.title')}</CardTitle>
          <Flow flow={removeResendCodeButton(flow)} flowType="recovery" onSubmit={onSubmit} />
        </Card>
      )}

      <Card className={clsx(sCard.marginCard, sCard.centerCard)}>
        <Link to="/">{t('recovery.navigation.home')}</Link>
      </Card>
    </>
  );
};
