import { useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Trans, useTranslation } from 'react-i18next';
import type { AxiosError } from 'axios';
import type {
  RegistrationFlow,
  UpdateRegistrationFlowBody,
} from '@ory/client';
import clsx from 'clsx';

import ory from '../../api';
import { Flow } from '../../components/Flow';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/CardTitle';
import { handleFlowError } from '../../utils/errors';

import sCard from '../../styles/card.module.css';

export const SignUp = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const flowId = searchParams.get('flow');
  const returnTo = searchParams.get('return_to');

  const [flow, setFlow] = useState<RegistrationFlow>();

  useEffect(() => {
    if (flow) {
      return;
    }

    if (flowId) {
      ory
        .getRegistrationFlow({ id: String(flowId) })
        .then(({ data }) => setFlow(data))
        .catch(handleFlowError(t, navigate, 'signup', setFlow));

      return;
    }

    ory
      .createBrowserRegistrationFlow({ returnTo: returnTo ? String(returnTo) : undefined })
      .then(({ data }) => setFlow(data))
      .catch(handleFlowError(t, navigate, 'signup', setFlow));
  }, [t, navigate, flow, flowId, returnTo]);

  const onSubmit = (values: UpdateRegistrationFlowBody) => {
    if (flow) {
      setSearchParams(new URLSearchParams([['flow', flow.id]]));
    }

    return ory
      .updateRegistrationFlow({
        flow: String(flow?.id), 
        updateRegistrationFlowBody: values
      })
      .then(() => navigate(flow?.return_to || '/'))
      .catch(handleFlowError(t, navigate, 'signup', setFlow, false))
      .catch((error: AxiosError) => {
        const responseStatus = error.response?.status;
        const responseData = error.response?.data;

        switch (responseStatus) {
          // Form validation error
          case 400:
            setFlow(responseData);
            return;

          default:
            throw error;
        }
      });
  };

  return (
    <>
      <Helmet defer={false} title={t('signup.documentTitle')} />

      <Card className={clsx(sCard.marginCard)}>
        <CardTitle>{t('signup.form.title')}</CardTitle>
        <Flow flow={flow} flowType="signup" onSubmit={onSubmit} />
      </Card>

      <Card className={clsx(sCard.marginCard, sCard.centerCard)}>
        <span>
          <Trans i18nKey="signup.navigation.signin">
            Already have an account? <Link to="/signin">Sign In</Link>
          </Trans>
        </span>
      </Card>
    </>
  );
};
