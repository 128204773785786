import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const detectorLookupKey = 'locale';

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV !== 'production',
    fallbackLng: 'en',
    detection: {
      lookupQuerystring: detectorLookupKey,
      lookupCookie: detectorLookupKey,
      lookupLocalStorage: detectorLookupKey,
      lookupSessionStorage: detectorLookupKey,
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
