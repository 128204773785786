import type { Dispatch, SetStateAction } from 'react';
import type { NavigateFunction } from 'react-router-dom';
import type { TFunction } from 'react-i18next';
import { toast } from 'react-toastify';
import type { AxiosError } from 'axios';

import type { FlowType } from '../types/helpers';

export const handleFlowError = <S>(
  t: TFunction,
  navigate: NavigateFunction,
  flowType: FlowType,
  resetFlow: Dispatch<SetStateAction<S | undefined>>,
  showToast = true,
) => {
  return async (error: AxiosError) => {
    switch (error.response?.data.error?.id) {
      case 'session_aal2_required':
        // 2FA is enabled and enforced, but user did not perform 2FA yet
        window.location.href = error.response?.data.redirect_browser_to;
        return;

      case 'session_already_available':
        // User is already signed in
        navigate('/settings');
        return;

      case 'session_refresh_required':
        // We need to re-authenticate to perform this action
        window.location.href = error.response?.data.redirect_browser_to;
        return;

      case 'self_service_flow_return_to_forbidden':
        // Flow expired - request a new one
        toast.error<string>(t('common.messages.return_to_forbidden'));
        resetFlow(undefined);
        navigate(`/${flowType}`);
        return;

      case 'self_service_flow_expired':
        // Flow expired - request a new one.
        if (showToast) {
          toast.error<string>(t('common.messages.flow_expired'));
        }
        resetFlow(undefined);
        navigate(`/${flowType}`);
        return;

      case 'security_csrf_violation':
        // A CSRF violation occurred - request a new one
        toast.error<string>(t('common.messages.security_csrf_violation'));
        resetFlow(undefined);
        navigate(`/${flowType}`);
        return;

      case 'security_identity_mismatch':
        // The requested item was intended for someone else - request a new one
        resetFlow(undefined);
        navigate(`/${flowType}`);
        return;

      case 'browser_location_change_required':
        // Ory Kratos asked us to point the user to this URL
        window.location.href = error.response.data.redirect_browser_to;
        return;

      default:
        break;
    }

    switch (error.response?.status) {
      case 404:
      case 410:
        // Flow expired - request a new one
        resetFlow(undefined);
        navigate(`/${flowType}`);
        return;

      default:
        break;
    }

    // We are not able to handle the error. Rethrow it
    throw error;
  };
};
