import { useTranslation } from 'react-i18next';
import { getNodeLabel } from '@ory/integrations/ui';
import { upperCaseFirst } from 'upper-case-first';

import { TextInput } from '../../TextInput';
import type { NodeInputProps } from '../../../types/helpers';

export function NodeInputDefault({
  flowType,
  node,
  attributes,
  value = '',
  setValue,
  disabled,
}: NodeInputProps) {
  const { t } = useTranslation();

  // Some attributes have dynamic JavaScript - this is for example required for WebAuthn.
  const onClick = () => {
    // This section is only used for WebAuthn. The script is loaded via a <script> node
    // and the functions are available on the global window level. Unfortunately, there
    // is currently no better way than executing eval / function here at this moment.
    if (attributes.onclick) {
      // eslint-disable-next-line no-new-func
      const run = new Function(attributes.onclick);
      run();
    }
  };

  return (
    <TextInput
      titleEl={t(
        `${flowType}.form.fields.${attributes.name}`,
        getNodeLabel(node) || upperCaseFirst(attributes.name),
      )}
      onClick={onClick}
      onChange={e => setValue(e.target.value)}
      type={attributes.type}
      name={attributes.name}
      value={value}
      disabled={attributes.disabled || disabled}
      state={node.messages.find(({ type }) => type === 'error') ? 'error' : undefined}
      subtitleEl={
        <>
          {node.messages.map(({ id, text }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <span key={`${id}-${index}`}>
              {t([`${flowType}.messages.${id}`, `common.messages.${id}`], text)}
            </span>
          ))}
        </>
      }
    />
  );
}
