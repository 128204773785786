import clsx from 'clsx';

import s from './styles.module.css';

type Props = React.HTMLAttributes<HTMLHeadingElement>;

export const AlertContent = ({ children, className, ...props }: Props) => (
  <span className={clsx(s.alertContent, className)} {...props}>
    {children}
  </span>
);
