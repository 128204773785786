import type { UiNode } from '@ory/client';

import type { Method } from '../types/helpers';

export const filterUINodesByMethod = (nodes?: UiNode[], method?: Method) => {
  if (!nodes) {
    return [];
  }

  return method ? nodes.filter(({ group }) => group === method) : nodes;
};
