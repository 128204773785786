import type { UiNode, UiNodeTextAttributes, UiText } from '@ory/client';
import clsx from 'clsx';

import { CodeBox } from '../../CodeBox';
import type { FlowType } from '../../../types/helpers';

import s from './styles.module.css';

interface Props {
  flowType: FlowType;
  node: UiNode;
  attributes: UiNodeTextAttributes;
}

interface ContentProps {
  flowType: FlowType;
  attributes: UiNodeTextAttributes;
}

const Content = ({ flowType, attributes }: ContentProps) => {
  switch (attributes.text.id) {
    case 1050015:
      // This text node contains lookup secrets. Let's make them a bit more beautiful!
      // eslint-disable-next-line no-case-declarations, @typescript-eslint/no-explicit-any
      const secrets = (attributes.text.context as any).secrets.map((text: UiText, k: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={k} className="col-xs-3">
          {/* Used lookup_secret has ID 1050014 */}
          <code>{text.id === 1050014 ? 'Used' : text.text}</code>
        </div>
      ));

      return (
        <div className="container-fluid">
          <div className="row">{secrets}</div>
        </div>
      );

    default:
      break;
  }

  return (
    <div>
      <CodeBox code={attributes.text.text} />
    </div>
  );
};

export const NodeText = ({ flowType, node, attributes }: Props) => {
  return (
    <>
      <p className={clsx(s.paragraph)}>{node.meta?.label?.text}</p>
      <Content flowType={flowType} attributes={attributes} />
    </>
  );
};
