import type { SyntheticEvent } from 'react';
import { getNodeLabel } from '@ory/integrations/ui';

import { Button } from '../../Button';
import type { NodeInputProps } from '../../../types/helpers';

export function NodeInputButton({ node, attributes, disabled, dispatchSubmit }: NodeInputProps) {
  // Some attributes have dynamic JavaScript - this is for example required for WebAuthn.
  const onClick = () => {
    // This section is only used for WebAuthn. The script is loaded via a <script> node
    // and the functions are available on the global window level. Unfortunately, there
    // is currently no better way than executing eval / function here at this moment.
    if (attributes.onclick) {
      // eslint-disable-next-line no-new-func
      const run = new Function(attributes.onclick);
      run();
    }
  };

  return (
    <Button
      name={attributes.name}
      onClick={(e: SyntheticEvent) => {
        onClick();
        dispatchSubmit(e);
      }}
      value={attributes.value || ''}
      disabled={attributes.disabled || disabled}
    >
      {getNodeLabel(node)}
    </Button>
  );
}
