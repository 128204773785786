import { Outlet } from 'react-router-dom';
import clsx from 'clsx';

import { Navbar } from '../../Navbar';

import s from './styles.module.css';

type Props = React.HTMLAttributes<HTMLDivElement>;

export const MainLayout = ({ className, ...props }: Props) => (
  <div className={clsx(s.container)} {...props}>
    {process.env.NODE_ENV === 'development' && (
      <header className={clsx(s.header)}>
        <Navbar />
      </header>
    )}

    <main className={clsx(s.content)}>
      <Outlet />
    </main>
  </div>
);
