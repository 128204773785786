import type { ReactNode } from 'react';
import clsx from 'clsx';

import s from './styles.module.css';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  labelClassName?: string;
  titleEl?: ReactNode;
  titleElClassName?: string;
  subtitleEl?: ReactNode;
  subtitleElClassName?: string;
  hasError?: boolean;
}

export const Checkbox = ({
  titleEl,
  subtitleEl,
  label,
  hasError,
  id,
  className,
  disabled,
  labelClassName,
  titleElClassName,
  subtitleElClassName,
  ...props
}: Props) => {
  const inputId = id || Math.random().toString(36).slice(2);

  return (
    <div className={clsx(s.wrapper, className)}>
      {titleEl && <div className={clsx(s.title, titleElClassName)}>{titleEl}</div>}

      <div>
        <input
          type="checkbox"
          id={inputId}
          className={clsx(s.input)}
          disabled={disabled}
          {...props}
        />

        <label htmlFor={inputId} className={clsx(s.label, labelClassName)}>
          {label}
        </label>
      </div>

      {subtitleEl && (
        <div className={clsx(s.subtitle, hasError && s.subtitleError, subtitleElClassName)}>
          {subtitleEl}
        </div>
      )}
    </div>
  );
};
