import clsx from 'clsx';

import s from './styles.module.css';

export interface CodeBoxProps extends React.HTMLAttributes<HTMLPreElement> {
  code: string;
}

export const CodeBox = ({ className, children, code, ...props }: CodeBoxProps) => (
  <pre className={clsx(s.codeBox, className)} {...props}>
    {/* eslint-disable-next-line react/no-danger */}
    <code className={clsx(s.code)} dangerouslySetInnerHTML={{ __html: code }} />
  </pre>
);
