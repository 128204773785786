import type { UiNode, UiNodeImageAttributes } from '@ory/client';

import type { FlowType } from '../../../types/helpers';

interface Props {
  flowType: FlowType;
  node: UiNode;
  attributes: UiNodeImageAttributes;
}

export const NodeImage = ({ flowType, node, attributes }: Props) => {
  return <img src={attributes.src} alt={node.meta.label?.text} />;
};
