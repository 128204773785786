import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import s from './styles.module.css';

export const NotFound = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet defer={false} title={t('notFound.documentTitle')} />

      <div className={clsx(s.wrapper)}>
        <h1 className={clsx(s.notFoundCode)}>404</h1>
        <h2 className={clsx(s.notFoundMessage)}>{t('notFound.notFoundMessage')}</h2>
      </div>
    </>
  );
};
