import {
  isUiNodeAnchorAttributes,
  isUiNodeImageAttributes,
  isUiNodeInputAttributes,
  isUiNodeScriptAttributes,
  isUiNodeTextAttributes,
} from '@ory/integrations/ui';
import type { UiNode } from '@ory/client';

import { NodeAnchor } from './NodeAnchor';
import { NodeImage } from './NodeImage';
import { NodeInput } from './NodeInput/NodeInput';
import { NodeScript } from './NodeScript/NodeScript';
import { NodeText } from './NodeText/NodeText';
import type { FormDispatcher, ValueSetter, FlowType } from '../../types/helpers';

interface Props {
  flowType: FlowType;
  node: UiNode;
  disabled: boolean;
  value: unknown;
  setValue: ValueSetter;
  dispatchSubmit: FormDispatcher;
}

export const Node = ({ flowType, node, value, setValue, disabled, dispatchSubmit }: Props) => {
  if (isUiNodeImageAttributes(node.attributes)) {
    return <NodeImage flowType={flowType} node={node} attributes={node.attributes} />;
  }

  if (isUiNodeScriptAttributes(node.attributes)) {
    return <NodeScript flowType={flowType} node={node} attributes={node.attributes} />;
  }

  if (isUiNodeTextAttributes(node.attributes)) {
    return <NodeText flowType={flowType} node={node} attributes={node.attributes} />;
  }

  if (isUiNodeAnchorAttributes(node.attributes)) {
    return <NodeAnchor flowType={flowType} node={node} attributes={node.attributes} />;
  }

  if (isUiNodeInputAttributes(node.attributes)) {
    return (
      <NodeInput
        flowType={flowType}
        node={node}
        attributes={node.attributes}
        value={value}
        disabled={disabled}
        setValue={setValue}
        dispatchSubmit={dispatchSubmit}
      />
    );
  }

  return null;
};
