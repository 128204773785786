import type { FormEvent } from 'react';
import type { UiNodeAnchorAttributes, UiNode } from '@ory/client';

import { Button } from '../../Button';
import type { FlowType } from '../../../types/helpers';

interface Props {
  flowType: FlowType;
  node: UiNode;
  attributes: UiNodeAnchorAttributes;
}

export const NodeAnchor = ({ flowType, node, attributes }: Props) => {
  return (
    <Button
      onClick={(e: FormEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        e.preventDefault();
        window.location.href = attributes.href;
      }}
    >
      {attributes.title.text}
    </Button>
  );
};
