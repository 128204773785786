import { useState, useEffect, useMemo, useCallback, createContext } from 'react';
import type { ReactNode } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import type { Session } from '@ory/client';
import type { AxiosError } from 'axios';

import ory from '../api';

export const SessionContext = createContext<{
  session?: Session;
  hasSession: boolean;
  signOut: () => Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
}>({ hasSession: false, signOut: async () => {} });

type Props = { children: ReactNode };

export const SessionProvider = ({ children }: Props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const aal = searchParams.get('aal');
  const refresh = searchParams.get('refresh');

  const [session, setSession] = useState<Session>();
  const [hasSession, setHasSession] = useState(false);
  const [signOutToken, setSignOutToken] = useState('');

  const signOut = useCallback(async () => {
    if (signOutToken) {
      await ory.updateLogoutFlow({ token: signOutToken });
      setSession(undefined);
      setHasSession(false);
      setSignOutToken('');
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signOutToken, aal, refresh, navigate]);

  useEffect(() => {
    ory
      .toSession()
      .then(({ data }) => {
        setSession(data);
        setHasSession(true);
      })
      .catch((error: AxiosError) => {
        const responseStatus = error.response?.status;

        switch (responseStatus) {
          // This is a legacy error code thrown. See code 422 for more details.
          case 403:
          // This status code is returned when we are trying to validate a session which has not yet completed it's second factor
          // eslint-disable-next-line no-fallthrough
          case 422:
            navigate('/signin?aal=aal2');
            return;

          // The user is not signed in
          case 401:
            setSession(undefined);
            setHasSession(false);
            setSignOutToken('');
            return;

          default:
            throw error;
        }
      });
  }, [navigate, signOutToken]);

  useEffect(() => {
    if (hasSession) {
      ory
        .createBrowserLogoutFlow()
        .then(({ data }) => setSignOutToken(data.logout_token))
        .catch((error: AxiosError) => {
          switch (error.response?.status) {
            // Do nothing, the user is not signed in
            case 401:
              return;

            default:
              throw error;
          }
        });
    }
  }, [hasSession]);

  const contextValue = useMemo(
    () => ({
      session,
      hasSession,
      signOut,
    }),
    [session, hasSession, signOut],
  );

  return <SessionContext.Provider value={contextValue}>{children}</SessionContext.Provider>;
};
