import type { ReactNode } from 'react';
import clsx from 'clsx';

import s from './styles.module.css';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  big?: boolean;
  helper?: ReactNode;
  children: string;
}

export const Button = ({ helper, className, big, disabled, type = 'submit', ...props }: Props) => (
  <div className={clsx(className)}>
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={clsx(s.button, big && s.buttonBig, disabled && s.buttonDisabled)}
      disabled={disabled}
      {...props}
    />

    {helper && <span>{helper}</span>}
  </div>
);
