import { UiText } from '@ory/client';

import { Message } from './Message';
import type { FlowType } from '../../types/helpers';

interface MessagesProps {
  flowType?: FlowType;
  messages?: Array<UiText>;
}

export const Messages = ({ flowType, messages }: MessagesProps) => {
  if (!messages) {
    return null;
  }

  return (
    <div>
      {messages.map(message => (
        <Message key={message.id} flowType={flowType} message={message} />
      ))}
    </div>
  );
};
