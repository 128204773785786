import type { ReactNode } from 'react';
import clsx from 'clsx';

import s from './styles.module.css';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  titleEl?: ReactNode;
  subtitleEl?: ReactNode;
  titleElClassName?: string;
  subtitleElClassName?: string;
  state?: 'success' | 'error' | 'disabled';
}

export const TextInput = ({
  className,
  titleEl,
  titleElClassName,
  subtitleEl,
  subtitleElClassName,
  type = 'text',
  disabled,
  ...props
}: Props) => {
  let { state } = props;

  if (disabled) {
    state = 'disabled';
  }

  return (
    <div className={clsx(s.wrapper, className)}>
      {titleEl && <div className={clsx(titleElClassName)}>{titleEl}</div>}

      <input
        type={type}
        className={clsx(
          s.input,
          state && s[`input${state[0].toUpperCase() + state.slice(1)}`],
          className,
        )}
        disabled={disabled}
        {...props}
      />

      {subtitleEl && (
        <div className={clsx(state === 'error' && s.subtitleError, subtitleElClassName)}>
          {subtitleEl}
        </div>
      )}
    </div>
  );
};
