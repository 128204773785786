import { useContext } from 'react';

import { SessionContext } from '../context/session';

export const useSession = () => {
  const context = useContext(SessionContext);

  if (context === undefined) {
    throw new Error('useSession hook was used outside of its Provider');
  }

  return context;
};
