import { useState, useEffect, useCallback, useRef } from 'react';
import type { FormEvent } from 'react';
import type {
  LoginFlow,
  RecoveryFlow,
  RegistrationFlow,
  SettingsFlow,
  VerificationFlow,
  UiNode,
} from '@ory/client';
import { getNodeId, isUiNodeInputAttributes } from '@ory/integrations/ui';

import { Messages } from '../Messages';
import { Node } from '../Node';
import type { FlowType, Method, Values } from '../../types/helpers';

type Props<T> = {
  flow?:
    | LoginFlow
    | RegistrationFlow
    | SettingsFlow
    | VerificationFlow
    | RecoveryFlow;
  flowType: FlowType;
  // Only show certain nodes. We will always render the default nodes for CSRF tokens
  onlyMethodToShow?: Method;
  onSubmit: (values: T) => Promise<void>;
  hideGlobalMessages?: boolean;
};

export const Flow = <T extends Values>(props: Props<T>): JSX.Element | null => {
  const { flow, flowType, onlyMethodToShow, hideGlobalMessages, onSubmit } = props;
  const isMounted = useRef(false);
  const [values, setValues] = useState({} as T);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const initializeValues = useCallback((nodes: Array<UiNode> = []) => {
    const newValues = {} as T;
    nodes.forEach(node => {
      // This only makes sense for text nodes
      if (isUiNodeInputAttributes(node.attributes)) {
        if (node.attributes.type === 'button' || node.attributes.type === 'submit') {
          // In order to mimic real HTML forms, we need to skip setting the value
          // for buttons as the button value will (in normal HTML forms) only trigger
          // if the user clicks it.
          return;
        }
        newValues[node.attributes.name as keyof Values] = node.attributes.value;
      }
    });

    setValues(newValues);
  }, []);

  const getValues = useCallback(
    (nodes: Array<UiNode> = []): T => {
      const newValues = {} as T;
      nodes.forEach(node => {
        // This only makes sense for text nodes
        if (isUiNodeInputAttributes(node.attributes)) {
          if (node.attributes.type === 'button' || node.attributes.type === 'submit') {
            newValues[node.attributes.name as keyof Values] = node.attributes.value;
          }
        }
      });

      return {
        ...values,
        ...newValues,
      };
    },
    [values],
  );

  const filterNodes = useCallback((): Array<UiNode> => {
    if (!flow) {
      return [];
    }

    return flow.ui.nodes.filter(({ group }) => {
      if (!onlyMethodToShow) {
        return true;
      }

      return group === 'default' || group === onlyMethodToShow;
    });
  }, [flow, onlyMethodToShow]);

  useEffect(() => {
    isMounted.current = true;
    initializeValues(filterNodes());
    return () => {
      isMounted.current = false;
    };
  }, [flow, initializeValues, filterNodes]);

  const handleSubmit = async (e: MouseEvent | FormEvent) => {
    e.preventDefault();

    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    try {
      await onSubmit(getValues(filterNodes()));
    } finally {
      if (isMounted.current) {
        setIsSubmitting(false);
      }
    }
  };

  // Filter the nodes - only show the ones we want
  const nodes = filterNodes();

  if (!flow) {
    return null;
  }

  return (
    <form action={flow.ui.action} method={flow.ui.method} onSubmit={handleSubmit}>
      {!hideGlobalMessages ? <Messages flowType={flowType} messages={flow.ui.messages} /> : null}

      {nodes.map((node, k) => {
        const id = getNodeId(node) as keyof Values;
        return (
          <Node
            // eslint-disable-next-line react/no-array-index-key
            key={`${id}-${k}`}
            node={node}
            value={values[id]}
            flowType={flowType}
            disabled={isSubmitting}
            dispatchSubmit={handleSubmit}
            setValue={async value =>
              setValues(prevValues => ({
                ...prevValues,
                [id]: value,
              }))
            }
          />
        );
      })}
    </form>
  );
};
