import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { useSession } from '../../hooks/useSession';
import { ReactComponent as GrolliLogo } from '../../icons/GrolliLogo.svg';
import { ReactComponent as PlayStoreBadge } from '../../icons/badges/PlayStore/en_get.svg';
import { ReactComponent as AppStoreBadge } from '../../icons/badges/AppStore/en_black.svg';

import s from './styles.module.css';

export const Home = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  const resolvedLanguage = i18n.resolvedLanguage.toLowerCase().slice(0, 2) || 'en';

  const { hasSession, signOut } = useSession();

  const playStoreAppLink = 'https://play.google.com/store/apps/details?id=com.grolli.one';
  const appStoreAppLink = `https://apps.apple.com/${
    resolvedLanguage === 'en' ? 'us' : resolvedLanguage
  }/app/grolli-one/id1598540412`;

  const playStoreBadgeLink = `${process.env.PUBLIC_URL}/badges/PlayStore/${resolvedLanguage}_get.svg`;
  const appStoreBadgeLink = `${process.env.PUBLIC_URL}/badges/AppStore/${resolvedLanguage}_black.svg`;

  const [isPlayStoreBadgeLoaded, setIsPlayStoreBadgeLoaded] = useState(false);
  const [isAppStoreBadgeLoaded, setIsAppStoreBadgeLoaded] = useState(false);

  useEffect(() => {
    let ignore = false;

    const playStoreImg = new Image();
    playStoreImg.onload = () => {
      if (!ignore) {
        setIsPlayStoreBadgeLoaded(true);
      }
    };
    playStoreImg.src = playStoreBadgeLink;

    const appStoreImg = new Image();
    appStoreImg.onload = () => {
      if (!ignore) {
        setIsAppStoreBadgeLoaded(true);
      }
    };
    appStoreImg.src = appStoreBadgeLink;

    return () => {
      ignore = true;
    };
  }, [resolvedLanguage, playStoreBadgeLink, appStoreBadgeLink]);

  return (
    <>
      <Helmet bodyAttributes={{ class: clsx(s.body) }} />

      <div className={clsx(s.container)}>
        <div className={clsx(s.content)}>
          <div className={clsx(s.logoWrapper)}>
            <GrolliLogo
              className={clsx(s.logo)}
              preserveAspectRatio="xMidYMid meet"
              width="100%"
              height="100%"
            />
          </div>

          <nav className={clsx(s.navigation)}>
            <ul className={clsx(s.menuList)}>
              {!hasSession && (
                <li>
                  <Link to="/signup" className={clsx(s.menuLink)}>
                    {t('home.navigation.signup')}
                  </Link>
                </li>
              )}

              {!hasSession && (
                <li>
                  <Link to="/signin" className={clsx(s.menuLink)}>
                    {t('home.navigation.signin')}
                  </Link>
                </li>
              )}

              {hasSession && (
                <li>
                  <Link to="/settings" className={clsx(s.menuLink)}>
                    {t('home.navigation.settings')}
                  </Link>
                </li>
              )}

              <li>
                <Link to="/verification" className={clsx(s.menuLink)}>
                  {t('home.navigation.verification')}
                </Link>
              </li>

              {!hasSession && (
                <li>
                  <Link to="/recovery" className={clsx(s.menuLink)}>
                    {t('home.navigation.recovery')}
                  </Link>
                </li>
              )}

              {hasSession && (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                <li className={clsx(s.menuLink)} onClick={signOut}>
                  {t('home.navigation.signout')}
                </li>
              )}
            </ul>
          </nav>

          <div className={clsx(s.appLinksContainer)}>
            <div className={clsx(s.appLinkContainer)}>
              <a href={playStoreAppLink} target="_blank" rel="noreferrer">
                {isPlayStoreBadgeLoaded ? (
                  <img
                    className={clsx(s.appLinkImage)}
                    alt="Google Play"
                    src={playStoreBadgeLink}
                  />
                ) : (
                  <PlayStoreBadge className={clsx(s.appLinkImage)} />
                )}
              </a>
            </div>

            <div className={clsx(s.appLinkContainer)}>
              <a href={appStoreAppLink} target="_blank" rel="noreferrer">
                {isAppStoreBadgeLoaded ? (
                  <img
                    className={clsx(s.appLinkImage, s.appLinkImageAppStore)}
                    alt="App Store"
                    src={appStoreBadgeLink}
                  />
                ) : (
                  <AppStoreBadge
                    className={clsx(s.appLinkImage, s.appLinkImageAppStore)}
                    preserveAspectRatio="xMidYMid meet"
                    width="100%"
                    height="100%"
                  />
                )}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
