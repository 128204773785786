import { useTranslation } from 'react-i18next';
import { getNodeLabel } from '@ory/integrations/ui';

import { Button } from '../../Button';
import type { NodeInputProps } from '../../../types/helpers';

export function NodeInputSubmit({
  flowType,
  node,
  attributes,
  disabled,
  dispatchSubmit,
}: NodeInputProps) {
  const { t } = useTranslation();

  return (
    <Button
      name={attributes.name}
      onClick={dispatchSubmit}
      value={attributes.value || ''}
      disabled={attributes.disabled || disabled}
    >
      {t(`${flowType}.form.submit`, getNodeLabel(node))}
    </Button>
  );
}
