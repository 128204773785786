import { useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import type { AxiosError } from 'axios';
import type { FlowError, GenericError } from '@ory/client';
import clsx from 'clsx';

import ory from '../../api';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/CardTitle';

import sCard from '../../styles/card.module.css';

export const Error = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const errorId = searchParams.get('id');

  const [errorData, setErrorData] = useState<FlowError>();

  useEffect(() => {
    if (errorData) {
      return;
    }

    ory
      .getFlowError({ id: String(errorId) })
      .then(({ data }) => setErrorData(data))
      .catch((error: AxiosError) => {
        const responseStatus = error.response?.status;

        switch (responseStatus) {
          // The error id could not be found
          case 404:
          // The error id could not be fetched due to e.g. a CSRF issue
          // eslint-disable-next-line no-fallthrough
          case 403:
          // The error id expired
          // eslint-disable-next-line no-fallthrough
          case 410:
            navigate('/');
            return;

          default:
            throw error;
        }
      });
  }, [navigate, errorData, errorId]);

  return (
    <>
      <Helmet defer={false} title={t('error.documentTitle')} />

      {errorData !== undefined && (
        <Card className={clsx(sCard.marginCard)}>
          <CardTitle>{t('error.card.title')}</CardTitle>
          <p>{(errorData.error as GenericError).message ?? errorData.id}</p>
        </Card>
      )}

      <Card className={clsx(sCard.marginCard, sCard.centerCard)}>
        <Link to="/">{t('error.navigation.home')}</Link>
      </Card>
    </>
  );
};
