import type { SettingsFlow } from '@ory/client';

import { Card } from '../Card';
import type { Props as CardProps } from '../Card';
import { filterUINodesByMethod } from '../../utils/filterUINodes';
import type { Method } from '../../types/helpers';

type Props = CardProps & {
  flow?: SettingsFlow;
  onlyMethodToShow?: Method;
};

export const SettingsCard = ({ flow, onlyMethodToShow, children, ...props }: Props) => {
  if (!flow) {
    return null;
  }

  const nodes = filterUINodesByMethod(flow.ui.nodes, onlyMethodToShow);

  if (!nodes.length) {
    return null;
  }

  return <Card {...props}>{children}</Card>;
};
