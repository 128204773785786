import { useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import type { AxiosError } from 'axios';
import type { LoginFlow, UpdateLoginFlowBody } from '@ory/client';
import clsx from 'clsx';

import ory from '../../api';
import { Flow } from '../../components/Flow';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/CardTitle';
import { Button } from '../../components/Button';
import { useSession } from '../../hooks/useSession';
import { handleFlowError } from '../../utils/errors';

import sCard from '../../styles/card.module.css';

export const SignIn = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const flowId = searchParams.get('flow');
  const returnTo = searchParams.get('return_to');
  const aal = searchParams.get('aal');
  const refresh = searchParams.get('refresh');

  const [flow, setFlow] = useState<LoginFlow>();
  const { signOut } = useSession();

  useEffect(() => {
    if (flow) {
      return;
    }

    if (flowId) {
      ory
        .getLoginFlow({ id: String(flowId) })
        .then(({ data }) => setFlow(data))
        .catch(handleFlowError(t, navigate, 'signin', setFlow));

      return;
    }

    ory
      .createBrowserLoginFlow({
        refresh: Boolean(refresh),
        aal: aal ? String(aal) : undefined,
        returnTo:returnTo ? String(returnTo) : undefined
      })
      .then(({ data }) => setFlow(data))
      .catch(handleFlowError(t, navigate, 'signin', setFlow));
  }, [t, navigate, flow, flowId, returnTo, refresh, aal]);

  const onSubmit = (values: UpdateLoginFlowBody) => {
    if (flow) {
      setSearchParams(new URLSearchParams([['flow', flow.id]]));
    }

    return ory
      .updateLoginFlow({
        flow: String(flow?.id), 
        updateLoginFlowBody: values
      })
      .then(() => {
        if (flow?.return_to) {
          window.location.href = flow?.return_to;
          return;
        }

        navigate('/settings');
      })
      .catch(handleFlowError(t, navigate, 'signin', setFlow))
      .catch((error: AxiosError) => {
        const responseStatus = error.response?.status;
        const responseData = error.response?.data;

        switch (responseStatus) {
          // Form validation error
          case 400:
            setFlow(responseData);
            return;

          default:
            throw error;
        }
      });
  };

  return (
    <>
      <Helmet defer={false} title={t('signin.documentTitle')} />

      {flow && flow.ui && (
        <Card className={clsx(sCard.marginCard)}>
          <CardTitle>
            {(() => {
              if (flow.refresh) {
                return t('signin.form.title_confirm');
              }

              if (flow.requested_aal === 'aal2') {
                return t('signin.form.title_mfa');
              }

              return t('signin.form.title');
            })()}
          </CardTitle>

          <Flow flow={flow} flowType="signin" onSubmit={onSubmit} />
        </Card>
      )}

      {aal || refresh ? (
        <Card className={clsx(sCard.marginCard)}>
          <Button type="button" onClick={signOut}>
            {t('signin.navigation.signout')}
          </Button>
        </Card>
      ) : (
        <>
          <Card className={clsx(sCard.marginCard, sCard.centerCard)}>
            <Link to="/signup">{t('signin.navigation.signup')}</Link>
          </Card>

          <Card className={clsx(sCard.marginCard, sCard.centerCard)}>
            <Link to="/recovery">{t('signin.navigation.recover_account')}</Link>
          </Card>
        </>
      )}
    </>
  );
};
