import { useTranslation } from 'react-i18next';
import type { UiText } from '@ory/client';

import { Alert } from '../Alert';
import { AlertContent } from '../AlertContent';
import type { FlowType } from '../../types/helpers';

interface MessageProps {
  flowType?: FlowType;
  message: UiText;
}

export const Message = ({ flowType, message }: MessageProps) => {
  const { t } = useTranslation();

  return (
    <Alert severity={message.type === 'error' ? 'error' : 'info'}>
      <AlertContent>
        {t([`${flowType}.messages.${message.id}`, `common.messages.${message.id}`], message.text)}
      </AlertContent>
    </Alert>
  );
};
