import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';

import { Home } from './pages/Home';
import { SignUp } from './pages/SignUp';
import { SignIn } from './pages/SignIn';
import { Settings } from './pages/Settings';
import { Verification } from './pages/Verification';
import { Recovery } from './pages/Recovery';
import { Error } from './pages/Error';
import { NotFound } from './pages/NotFound';
import { MainLayout } from './components/layouts/MainLayout';
import { FullScreenLayout } from './components/layouts/FullScreenLayout';
import { SessionProvider } from './context/session';

import 'react-toastify/dist/ReactToastify.css';

const App = (): JSX.Element => {
  const { t, i18n } = useTranslation();

  return (
    <HelmetProvider>
      <Helmet defer={false} defaultTitle="Grolli" titleTemplate="%s — Grolli">
        <html lang={i18n.resolvedLanguage} />
        <meta name="description" content={t('common.meta.description')} />
      </Helmet>

      <ToastContainer />

      <BrowserRouter>
        <SessionProvider>
          <Routes>
            <Route path="/" element={<Home />} />

            <Route element={<MainLayout />}>
              <Route path="/signup" element={<SignUp />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/verification" element={<Verification />} />
              <Route path="/recovery" element={<Recovery />} />
              <Route path="/error" element={<Error />} />
            </Route>

            <Route element={<FullScreenLayout />}>
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </SessionProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
};

export default App;
