import type { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { getNodeLabel } from '@ory/integrations/ui';
import { upperCaseFirst } from 'upper-case-first';

import { Checkbox } from '../../Checkbox';

import type { NodeInputProps } from '../../../types/helpers';

export function NodeInputCheckbox({
  flowType,
  node,
  attributes,
  setValue,
  disabled,
}: NodeInputProps) {
  const { t } = useTranslation();

  return (
    <Checkbox
      name={attributes.name}
      defaultChecked={attributes.value === true}
      onChange={(e: ChangeEvent<HTMLInputElement>) => setValue(e.target.checked)}
      disabled={attributes.disabled || disabled}
      label={t(
        `${flowType}.form.fields.${attributes.name}`,
        getNodeLabel(node) || upperCaseFirst(attributes.name),
      )}
      hasError={node.messages.some(({ type }) => type === 'error')}
      subtitleEl={
        <>
          {node.messages.map(({ id, text }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <span key={`${id}-${index}`}>
              {t([`${flowType}.messages.${id}`, `common.messages.${id}`], text)}
            </span>
          ))}
        </>
      }
    />
  );
}
