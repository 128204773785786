import clsx from 'clsx';

import { Loader } from '../../Loader';

import s from './styles.module.css';

type Props = React.HTMLAttributes<HTMLDivElement>;

export const LoaderLayout = ({ className, ...props }: Props) => (
  <div className={clsx(s.container)} {...props}>
    <main className={clsx(s.content)}>
      <Loader />
    </main>
  </div>
);
